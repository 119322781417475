<template>
    <div>
        <ts-page-title
            :title="$t('leaveReport.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveReport.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card border-0 bg-gray-900">
                            <div class="card-body">
                                <div class="text-gray-500">
                                    <b>{{
                                        $t('disbursementList.filterCriteria')
                                    }}</b>
                                </div>
                            </div>
                            <div class="widget-list rounded-bottom">
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label required"
                                            >{{
                                                $t('leaveReport.branch')
                                            }}</label
                                        >
                                        <ts-branch-filter
                                            @filter="
                                                value =>
                                                    (model.branch_id = value)
                                            "
                                            :isPlaceholder="true"
                                            :class="{
                                                'is-invalid':
                                                    errors.has('template_id')
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('branch_id')"
                                        >
                                            {{ errors.first('branch_id') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label required"
                                            >{{ $t('leaveReport.date') }}</label
                                        >
                                        <DatePicker
                                            type="daterange"
                                            v-model="model.dateRange"
                                            placement="bottom-end"
                                            :placeholder="$t('selectDateRange')"
                                            style="width: 100%"
                                            :transfer="true"
                                            format="dd-MM-yyyy"
                                            @on-change="
                                                onChangeDisbursementDate
                                            "
                                        ></DatePicker>
                                    </div>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t('leaveReport.staffName')
                                            }}</label
                                        >
                                        <Select
                                            v-model="model.employee_id"
                                            filterable
                                            multiple
                                            :remote-method="loadEmployee"
                                            :clearable="true"
                                            :class="{
                                                'is-invalid':
                                                    errors.has('employee_id')
                                            }"
                                        >
                                            <Option
                                                v-for="(
                                                    employeeProfile, index
                                                ) in employeeProfiles"
                                                :value="
                                                    employeeProfile.employee_id
                                                "
                                                :key="index"
                                                >{{
                                                    employeeProfile.employee_name_en
                                                }}|{{
                                                    employeeProfile.employee_name_kh
                                                }}
                                            </Option>
                                        </Select>
                                    </div>
                                </div>
                                <div
                                    class="tw-justify-end tw-flex tw-space-x-2"
                                >
                                    <ts-button
                                        outline
                                        color="success"
                                        :waiting="exporting"
                                        @click.prevent="exportExcel"
                                    >
                                        <i
                                            class="far fa-file-excel"
                                            v-if="!exporting"
                                        ></i>
                                        {{ $t('exportExcel') }}</ts-button
                                    >
                                    <ts-button
                                        color="danger"
                                        outline
                                        :waiting="waiting"
                                        @click.prevent="preview"
                                    >
                                        <i
                                            class="far fa-file-pdf"
                                            v-if="!waiting"
                                        ></i>
                                        {{ $t('previewPdf') }}</ts-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div id="container">
                            <ts-preview-pdf v-model="waiting" :src="src" />
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import Errors from 'form-backend-validation/dist/Errors'
import moment from 'moment'
import { mapActions } from 'vuex'
import { trim,debounce } from 'lodash'
import * as FileDownload from 'downloadjs'

export default {
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            loading: false,
            exporting: false,
            src: '',
            model: {
                branch_id: null,
                employee_id: [],
                dateRange: [
                    moment().format('DD-MM-YYYY'),
                    moment().format('DD-MM-YYYY')
                ]
            }
        }
    },
    created () {
        this.fetchResource()
    },
    computed: {
        employeeProfiles () {
            return this.$store.state.report.leaveReport.employeeProfiles
        }
    },
    methods: {
        ...mapActions('report/leaveReport', ['getEmployeeProfile']),
        fetchResource () {
            this.loading = true
            this.getEmployeeProfile()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadEmployee: debounce(function (query) {
            this.getEmployeeProfile({ search: query })
        }, 1000),
        onChangeDisbursementDate (dateRange) {
            this.model.dateRange = dateRange
        },
        preview () {
            this.errors = new Errors()
            this.waiting = true
            this.src = ''
            this.$store
                .dispatch('report/leaveReport/leaveReportPdf', this.model)
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        exportExcel() {
			this.errors = new Errors()
			this.exporting = true

			this.$store
				.dispatch('report/leaveReport/exportExcel', {
					branch_id: this.model.branch_id,
					dateRange: this.model.dateRange,
					employee_id: this.model.employee_id,
				})
				.then(response => {
					let filename = trim(
						response.headers['content-disposition']
							.substring(
								response.headers['content-disposition'].indexOf(
									'filename'
								)
							)
							.replace('filename=', ''),
						'"'
					)
					FileDownload(response.data, filename)
				})
				.catch(err => {
					let error = JSON.parse(
						String.fromCharCode.apply(null, new Uint8Array(err))
					)
					this.notice({ type: 'error', text: error.message })
					this.errors = new Errors(error.errors)
				})
				.finally(() => (this.exporting = false))
		},
        notice (not) {
            this.$Notice[not.type]({
                title: 'LEAVE REPORT',
                desc: not.text
            })
        }
    }
}
</script>
